import { FooterLinkType } from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'

export const footerLinks: (t: ContextApi['t']) => FooterLinkType[] = (t) => [
  {
    label: t('About'),
    items: [
      {
        label: t('Contact'),
        href: 'bnbtiger9527@gmail.com',
        isHighlighted: true,
      },
      {
        label: t('Brand'),
        href: 'http://bnbtiger.top',
      },
      {
        label: t('Audit'),
        href: 'https://app.solidproof.io/projects/bnb-tiger',
      },
      {
        label: t('Community'),
        href: 'https://t.me/BNBTigerInu',
      },
      {
        label: t('Litepaper'),
        href: 'https://ryolin.website/Whitepaper.pdf',
      },
    ],
  },
]
